<template>
  <div class="channel-input">
    <img
      v-if="showChannelIcon"
      :src="channel.icon"
      width="32"
      height="32"
      :style="{
        filter: state.filterValue,
      }"
    />

    <div class="input-tag__wrapper">
      <input-prefix
        :default-value="state.channelAddress"
        :is-focus-out-event="true"
        @updateData="(value) => actions.updateChannelUrl(value)"
        @submit="actions.submitEvent()"
      ></input-prefix>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, watch } from "vue";
import InputPrefix from "../../../../components/console/inputs/InputPrefix";
import { useStore } from "vuex";
import swal from "@/helper/swal";

export default {
  name: "ChannelInput",
  components: { InputPrefix },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    registeredChannel: {
      type: Object,
      required: false,
    },
    showChannelIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      channelAddress: "",
      filterValue: computed(() => {
        return !props.registeredChannel
          ? "invert(94%) sepia(11%) saturate(95%) hue-rotate(202deg) brightness(87%) contrast(86%)"
          : "";
      }),
      defaultValue: "",
      isChanged: computed(() => {
        return state.channelAddress.trim() !== state.defaultValue;
      }),
    });

    watch(
      () => props.channel,
      () => {
        initChannelInput();
      }
    );

    onBeforeMount(() => {
      initChannelInput();
    });

    const initChannelInput = () => {
      if (props.registeredChannel) {
        state.channelAddress = props.registeredChannel.url;
      } else {
        state.channelAddress = props.channel.url;
      }
      state.defaultValue = state.channelAddress;
    };

    const createSpaceChannel = (url) => {
      store.dispatch("space/postSpaceChannel", { url: url }).then((res) => {
        if (res.data.success) {
          swal.messageAlert("sns 링크가 등록 되었습니다.");
        } else {
          swal.messageAlert(res.data.message);
          initChannelInput();
        }
      });
    };

    const updateSpaceChannel = (spaceChannelResourceId, url) => {
      store
        .dispatch("space/putSpaceChannel", {
          spaceChannelResourceId: spaceChannelResourceId,
          data: {
            url: url,
          },
        })
        .then(() => {
          swal.messageAlert("sns 링크가 수정 되었습니다.");
        });
    };

    const deleteSpaceChannel = (spaceChannelResourceId) => {
      store
        .dispatch("space/deleteSpaceChannel", {
          spaceChannelResourceId: spaceChannelResourceId,
        })
        .then(() => {
          swal.deleteCompleteToast();
        });
    };

    const actions = {
      updateChannelUrl: (value) => {
        state.channelAddress = value;
      },
      submitEvent: () => {
        let channelUrl;
        let secureRegex = /^https:\/\//i;

        if (!secureRegex.test(state.channelAddress)) {
          channelUrl = "https://" + state.channelAddress;
        } else {
          channelUrl = state.channelAddress;
        }

        if (props.registeredChannel) {
          if (!state.channelAddress) {
            return deleteSpaceChannel(props.registeredChannel.resourceId);
          }

          if (state.isChanged) {
            return updateSpaceChannel(
              props.registeredChannel.resourceId,
              channelUrl
            );
          }
        } else {
          if (state.channelAddress && state.isChanged) {
            createSpaceChannel(channelUrl);
          }
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>

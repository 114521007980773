<template>
  <base-modal title="링크 추가" @closeModal="actions.hideModal()">
    <template #modalBody>
      <input-search
        v-model="state.searchKey"
        placeholder="SNS를 검색해주세요"
        search-type="typingText"
        :underline-border="true"
        shape="underline"
      ></input-search>

      <page-loading v-if="state.loading" :is-page="false"></page-loading>

      <ul v-if="!state.loading" class="channel-list">
        <li
          v-for="channel in state.filteredChannelList"
          :key="`channel-${channel.resourceId}`"
        >
          <channel-item
            :channel="channel"
            @click="actions.openChannelInput(channel)"
          ></channel-item>
        </li>
      </ul>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputSearch from "../../../../components/console/inputs/InputSearch";
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import PageLoading from "../../../../components/console/loadings/PageLoading";
import ChannelItem from "../ChannelItem/ChannelItem";
import channelConst from "../../../../consts/channel";

export default {
  name: "ChannelListModal",
  components: { ChannelItem, PageLoading, InputSearch, BaseModal },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      loading: true,
      searchKey: "",
      channelList: computed(() => {
        return store.getters["channels/constChannels"];
      }),
      filteredChannelList: computed(() => {
        let linkChannel = state.channelList.find(
          (item) => item.code === channelConst.channelPrefix.link.code
        );
        let remainChannels = state.channelList.filter(
          (item) => item.code !== channelConst.channelPrefix.link.code
        );

        let result = [linkChannel, ...remainChannels];

        if (state.searchKey) {
          return result.filter((channel) => {
            return channel.title
              .toUpperCase()
              .includes(state.searchKey.toUpperCase());
          });
        } else {
          return result;
        }
      }),
    });

    onBeforeMount(() => {
      if (state.channelList.length > 0) {
        state.loading = false;
      } else {
        store.dispatch("channels/getConstChannels").then(() => {
          state.loading = false;
        });
      }
    });

    const actions = {
      hideModal: () => {
        emit("hideModal");
      },
      openChannelInput: (channel) => {
        emit("openChannelInput", channel);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>

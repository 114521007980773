<template>
  <div class="onboarding-page__wrapper">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading" class="channel-page">
      <h2>
        거의 다 왔어요!<br />
        크리에이터님의 활동을 보여줄 SNS나 홈페이지가 있나요?
      </h2>
      <p class="sub-text-s2 text-gray-second">
        활동 중이신 SNS나 홈페이지 혹은 출판한 책, 인터뷰 기사, 블로그 등
        크리에이터님에 대해 알 수 있는 링크를 알려주세요.
      </p>

      <ul class="channel-list">
        <!--  note 등록된 채널 바인딩  -->
        <li
          v-for="channel in state.spaceChannels"
          :key="`registered-channel-${channel.resourceId}`"
        >
          <channel-input
            :channel="channel"
            :registered-channel="channel"
          ></channel-input>
        </li>

        <!--  note prefix 채널 바인딩  -->
        <li
          v-for="(channel, index) in state.prefixChannels"
          :key="`prefix-channel-${index + 1}`"
        >
          <channel-input :channel="channel"></channel-input>
        </li>
      </ul>

      <div class="other-channels__btn">
        <button-text
          text="다른 링크를 공유할래요"
          :is-icon="true"
          icon-position="front"
          @click="actions.openChannelsModal()"
        >
          <template #icon>
            <plus-icon></plus-icon>
          </template>
        </button-text>
      </div>

      <button-basic
        class="save-btn"
        text="신청완료"
        :disabled="!state.activeNextBtn"
        @action="actions.goToComplete()"
      ></button-basic>
    </div>

    <teleport to="#teleport">
      <channel-list-modal
        v-if="state.showChannelsModal"
        @hideModal="closeChannelsModal()"
        @openChannelInput="(channel) => actions.openChannelInputModal(channel)"
      ></channel-list-modal>

      <channel-input-modal
        v-if="state.showChannelInputModal"
        :channel="state.selectedChannel"
        @hideModal="closeChannelInputModal()"
        @backModal="actions.openChannelsModal()"
      ></channel-input-modal>
    </teleport>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import onBoardingConst from "../../../../consts/onboarding";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import { computed, getCurrentInstance, onBeforeMount, reactive } from "vue";
import ChannelInput from "../ChannelInput/ChannelInput";
import PageLoading from "../../../../components/console/loadings/PageLoading";
import ButtonText from "../../../../components/console/buttons/ButtonText";
import PlusIcon from "../../../../components/console/icons/PlusIcon";
import ChannelListModal from "../ChannelListModal/ChannelListModal";
import ChannelInputModal from "../ChannelInputModal/ChannelInputModal";
import channelConst from "../../../../consts/channel";

export default {
  name: "OnBoardingChannel",
  components: {
    ChannelInputModal,
    ChannelListModal,
    PlusIcon,
    ButtonText,
    PageLoading,
    ChannelInput,
    ButtonBasic,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      pageLoading: true,
      spaceChannels: computed(() => {
        return store.getters["space/spaceChannels"];
      }),
      prefixChannels: computed(() => {
        let result = [
          {
            code: "instagram",
            icon: `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_instagram.svg`,
            url: channelConst.channelPrefix.instagram.domain,
          },
          {
            code: "youtube",
            icon: `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_youtube.svg`,
            url: channelConst.channelPrefix.youtube.domain,
          },
          {
            code: "naverBlog",
            icon: `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_naverBlog.svg`,
            url: channelConst.channelPrefix.naverBlog.domain,
          },
          {
            code: "link",
            icon: `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_link.svg`,
            url: channelConst.channelPrefix.link.domain,
          },
        ];
        if (state.spaceChannels.length <= 4) {
          result.splice(0, state.spaceChannels.length);
          return result;
        } else {
          return [];
        }
      }),
      activeNextBtn: computed(() => {
        return state.spaceChannels.length > 0;
      }),
      showChannelsModal: false,
      showChannelInputModal: false,
      selectedChannel: null,
    });

    onBeforeMount(() => {
      store.dispatch("space/getSpaceChannels").then(() => {
        state.pageLoading = false;
      });
    });

    const closeChannelInputModal = () => {
      state.showChannelInputModal = false;
      state.selectedChannel = null;
    };

    const closeChannelsModal = () => {
      state.showChannelsModal = false;
    };

    const actions = {
      goToComplete: () => {
        store
          .dispatch("onBoardings/putOnBoardingStep", {
            step: onBoardingConst.onBoardingSteps.channel,
          })
          .then(() => {
            router.push({ name: "console.onboarding.complete" });
          });
      },
      openChannelsModal: () => {
        if (state.showChannelInputModal) {
          state.showChannelInputModal = false;
        }
        state.showChannelsModal = true;
      },
      openChannelInputModal: (channel) => {
        closeChannelsModal();
        state.selectedChannel = channel;
        state.showChannelInputModal = true;
      },
    };

    return { state, actions, closeChannelInputModal, closeChannelsModal };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <div class="channel-item">
    <img :src="channel.icon" width="24" height="24" />
    <span class="sub-text-s2">{{ channel.title }}</span>
  </div>
</template>

<script>
export default {
  name: "ChannelItem",
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <base-modal title="링크 주소 입력" @closeModal="actions.hideModal()">
    <template #modalBody>
      <p class="channel-title sub-text-s2">{{ `${channel.title} 주소` }}</p>

      <input-prefix
        :default-value="state.channelUrl"
        :placeholder="state.placeholder"
        @updateData="(value) => actions.updateChannelUrl(value)"
      ></input-prefix>

      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          @action="actions.backModal()"
        ></button-basic>
        <button-basic
          class="save-btn"
          :disabled="state.disableAddBtn"
          text="추가"
          @action="actions.addChannel()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../components/common/BaseModal.vue";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import { computed, reactive } from "vue";
import InputPrefix from "../../../../components/console/inputs/InputPrefix";
import channelConst from "@/consts/channel";
import { useStore } from "vuex";
import swal from "@/helper/swal";

export default {
  name: "ChannelInputModal",
  components: { InputPrefix, ButtonBasic, BaseModal },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      channelUrl: channelConst.channelPrefix[props.channel.code].domain,
      disableAddBtn: computed(() => {
        return !state.channelUrl;
      }),
      placeholder: channelConst.channelPrefix[props.channel.code].placeholder,
    });

    const actions = {
      hideModal: () => {
        emit("hideModal");
      },
      updateChannelUrl: (value) => {
        state.channelUrl = value;
      },
      addChannel: () => {
        let channelUrl;
        let secureRegex = /^https:\/\//i;

        if (!secureRegex.test(state.channelUrl)) {
          channelUrl = "https://" + state.channelUrl;
        } else {
          channelUrl = state.channelUrl;
        }

        store
          .dispatch("space/postSpaceChannel", { url: channelUrl })
          .then((res) => {
            if (res.data.success) {
              swal.messageAlert("sns 링크가 등록 되었습니다.");
              emit("hideModal");
            } else {
              swal.messageAlert(res.data.message);
              state.channelUrl = "";
            }
          });
      },
      backModal: () => {
        emit("backModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>

const channelConst = {
  channelPrefix: {
    link: {
      code: "link",
      domain: "",
      placeholder: "bigc.im/yourwebsite",
    },
    instagram: {
      code: "instagram",
      domain: "instagram.com/",
      placeholder: "yourname",
    },
    youtube: {
      code: "youtube",
      domain: "youtube.com/@",
      placeholder: "yourname",
    },
    twitter: {
      code: "tweeter",
      domain: "twitter.com/",
      placeholder: "yourname",
    },
    tiktok: {
      code: "tiktok",
      domain: "tiktok.com/@",
      placeholder: "yourname",
    },
    facebook: {
      code: "facebook",
      domain: "facebook.com/",
      placeholder: "yourname",
    },
    linkedin: {
      code: "linkedin",
      domain: "linkedin.com/in/",
      placeholder: "yourname",
    },
    brunch: {
      code: "brunch",
      domain: "brunch.co.kr/",
      placeholder: "yourname",
    },
    naverBlog: {
      code: "naverBlog",
      domain: "blog.naver.com/",
      placeholder: "yourname",
    },
    soundcloud: {
      code: "soundcloud",
      domain: "soundcloud.com/",
      placeholder: "yourname",
    },
    melon: {
      code: "melon",
      domain: "",
      placeholder: "melon.com/",
    },
    appleMusic: {
      code: "appleMusic",
      domain: "",
      placeholder: "music.apple.com/",
    },
    spotify: {
      code: "spotify",
      domain: "",
      placeholder: "open.spotify.com/",
    },
    tistory: {
      code: "tistory",
      domain: "",
      placeholder: "yourname.tistory.com/",
    },
    website: {
      code: "website",
      domain: "",
      placeholder: "bigc.im/yourwebsite",
    },
    kakaoOpenChat: {
      code: "kakaoOpenChat",
      domain: "",
      placeholder: "open.kakao.com/",
    },
    telegram: {
      code: "telegram",
      domain: "t.me/",
      placeholder: "yourname",
    },
    email: {
      code: "email",
      domain: "",
      placeholder: "your@email.com",
    },
    toss: {
      code: "toss",
      domain: "toss.me/",
      placeholder: "yourname",
    },
    github: {
      code: "github",
      domain: "github.com/",
      placeholder: "yourname",
    },
    medium: {
      code: "medium",
      domain: "medium.com/@",
      placeholder: "yourname",
    },
    kakaoMusic: {
      code: "kakaoMusic",
      domain: "",
      placeholder: "",
    },
    bugs: {
      code: "lbugsink",
      domain: "",
      placeholder: "music.bugs.co.kr/artist/",
    },
    genie: {
      code: "genie",
      domain: "",
      placeholder: "www.genie.co.kr/",
    },
    flo: {
      code: "flo",
      domain: "",
      placeholder: "www.music-flo.com/",
    },
    youtubeMusic: {
      code: "youtubeMusic",
      domain: "",
      placeholder: "music.youtube.com/channel/",
    },
    vibe: {
      code: "vibe",
      domain: "",
      placeholder: "vibe.naver.com/",
    },
    podcasts: {
      code: "podcasts",
      domain: "",
      placeholder: "podcasts.apple.com/",
    },
    afreecatv: {
      code: "afreecatv",
      domain: "bj.afreecatv.com/",
      placeholder: "yourname",
    },
    twitch: {
      code: "twitch",
      domain: "twitch.tv/",
      placeholder: "yourname",
    },
    naverNow: {
      code: "naverNow",
      domain: "",
      placeholder: "now.naver.com/",
    },
    discord: {
      code: "discord",
      domain: "",
      placeholder: "discord.com/",
    },
    weverse: {
      code: "weverse",
      domain: "weverse.io/",
      placeholder: "yourname",
    },
    patreon: {
      code: "patreon",
      domain: "patreon.com/",
      placeholder: "yourname",
    },
    postype: {
      code: "postype",
      domain: "postype.com/profile/@",
      placeholder: "yourname",
    },
    kyobo: {
      code: "kyobo",
      domain: "",
      placeholder: "kyobobook.co.kr/",
    },
    yes24: {
      code: "yes24",
      domain: "",
      placeholder: "yes24.com/",
    },
    aladin: {
      code: "aladin",
      domain: "",
      placeholder: "aladin.co.kr/",
    },
    ridi: {
      code: "ridi",
      domain: "",
      placeholder: "ridibooks.com/",
    },
    kakaoWebToon: {
      code: "kakaoWebToon",
      domain: "",
      placeholder: "webtoon.kakao.com/",
    },
    naverWebToon: {
      code: "naverWebToon",
      domain: "",
      placeholder: "comic.naver.com/webtoon/",
    },
    lezhin: {
      code: "lezhin",
      domain: "",
      placeholder: "www.lezhin.com/ko/comic/",
    },
    toptoon: {
      code: "toptoon",
      domain: "",
      placeholder: "toptoon.com/comic/",
    },
    toomics: {
      code: "toomics",
      domain: "",
      placeholder: "www.toomics.com/webtoon/",
    },
    bomtoon: {
      code: "bomtoon",
      domain: "",
      placeholder: "www.bomtoon.com/comic/",
    },
    netflix: {
      code: "netflix",
      domain: "",
      placeholder: "www.netflix.com/",
    },
    disney: {
      code: "disney",
      domain: "",
      placeholder: "www.disneyplus.com/",
    },
    tving: {
      code: "tving",
      domain: "",
      placeholder: "www.tving.com/",
    },
    wavve: {
      code: "wavve",
      domain: "",
      placeholder: "www.wavve.com/",
    },
    appletv: {
      code: "appletv",
      domain: "",
      placeholder: "tv.apple.com/",
    },
    appStore: {
      code: "appStore",
      domain: "",
      placeholder: "apps.apple.com/",
    },
    googlePlayStore: {
      code: "googlePlayStore",
      domain: "",
      placeholder: "play.google.com/store/apps/",
    },
    navertv: {
      code: "navertv",
      domain: "",
      placeholder: "tv.naver.com/",
    },
    naverSmartStore: {
      code: "naverSmartStore",
      domain: "",
      placeholder: "smartstore.naver.com/",
    },
    kakaoPage: {
      code: "kakaoPage",
      domain: "",
      placeholder: "page.kakao.com/",
    },
  },
};

export default channelConst;
